import React from 'react';
import CubeIcon from '../../../../assets/icons/cubeIcon.svg';

const Feature = props => {
  const { featureName } = props;
  return (
    <div className='flex justify-center items-center text-center align-middle  bg-secondary_feature bg-gray-800 p-3 px-4 mx-2 my-2 '>
      <CubeIcon width={28} height={28} />
      <p className='flex text-white align-middle items-center my-auto ml-3 p-2'>{featureName}</p>
    </div>
  );
};

export default Feature;
