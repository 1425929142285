import React from 'react';
import FeatureContainer from './projectPage/FeatureContainer';
import Layout from '../Layout';
import { useTranslation } from 'react-i18next';
import withTranslations from '../hoc/withTranslations';
import Seo from '../Seo';
import Together from '../Together';
import Link from '../Link';

const ProjectDetail = ({ pageContext }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout section='PROJECTS'>
      <Seo
        title={`${pageContext.project.title} | ${t('projectDetails.metaTitle')}`}
        description={pageContext.project.description}
        keywords={[
          'desarrollo',
          'web',
          'proyecto',
          'positive zero',
          'sistemas',
          'desarrollo web',
          'programadores',
          'software',
          'saltillo',
          'portafolio'
        ]}
      />
      <div className='md:p-0'>
        <div className='p-4 max-w-6xl mx-auto'>
          <Link to={`/projects`}>
            <a aria-label='Projects' className='cursor-pointer text-primary text-lg'>
              {`< ${t('projectDetails.back')}`}
            </a>
          </Link>
          <h3 className='text-white font-bold mt-8 text-3xl'>{pageContext.project.title}</h3>
          <p className='text-white text-xl mt-4'>{pageContext.project.description}</p>
        </div>

        <div className='my-4 w-full flex overflow-x-scroll pb-4 space-x-8' style={{ maxHeight: '500px' }}>
          {pageContext.project.sliderImages.map(i => (
            <img
              src={require(`../../images/projects/${i}`)}
              alt={`${pageContext.project.title} - ${i}`}
              key={i}
              className='object-contain rounded-lg shadow-md'
              style={{ maxHeight: 500 }}
            />
          ))}
        </div>

        <div className='bg-gray-800 my-20 p-4 md:p-16  max-w-6xl mx-4 md:mx-auto'>
          <div className='md:flex'>
            <div className='flex-1 md:w-2/3'>
              <h5 className='text-primary font-semibold uppercase'>{t('projectDetails.goal')}</h5>
              <p className='text-white my-2 mr-6'>{pageContext.project.goal}</p>
            </div>
            <div className='mt-12 md:w-1/3 md:mt-0 grid grid-cols-2 grid-rows-2 gap-4'>
              {pageContext.project.traits.map(t => (
                <div className='' key={t.name}>
                  <h5 className='text-primary font-semibold uppercase'>{t.name}</h5>
                  <p className='text-white my-2'>{t.value}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='mt-8'>
            <div>
              <h5 className='text-primary font-semibold uppercase'>{t('projectDetails.challenge')}</h5>
              <p className='text-white my-2'>{pageContext.project.challenge}</p>
            </div>
          </div>
        </div>
        <div className='max-w-6xl mx-auto'>
          <h3 className='text-primary mx-4 mt-4 font-semibold text-2xl'>{t('projectDetails.features')}</h3>
          <FeatureContainer features={pageContext.project.features} />
          <Together />
        </div>
      </div>
    </Layout>
  );
};

export default withTranslations()(ProjectDetail);
